// App Global CSS
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply globally. These styles are for
// the entire app and not just one component. Additionally, this file can be
// used as an entry point to import other CSS/Sass files to be included in the
// output CSS.
// For more information on global stylesheets, visit the documentation:
// https://ionicframework.com/docs/layout/global-stylesheets

// Core CSS required for Ionic components to work properly
@import '~@ionic/angular/css/core.css';

// Basic CSS for apps built with Ionic
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

// Optional CSS utils that can be commented out
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
@import '~@angular/material/theming';

@include mat-core();
$candy-app-primary: mat-palette($mat-indigo);
$candy-app-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

@include angular-material-theme($candy-app-theme);

@import '~saturn-datepicker/theming';

@include sat-datepicker-theme($candy-app-theme);

html {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Helvetica Neue',
    'Yu Gothic',
    YuGothic,
    'ヒラギノ角ゴ ProN W3',
    Hiragino Kaku Gothic ProN,
    Arial,
    'メイリオ',
    Meiryo,
    sans-serif;
  font-feature-settings: 'pkna' 1;
  // font-family: -apple-system;
  font-size: 10px;
}

body {
  @extend html;
  color: var(--nrm-color-text);
  font-size: 1.6rem;
}

a {
  color: inherit;
}

input,
textarea {
  background-color: transparent;
  margin: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

.main {
  ion-content {
    --padding-top: 32px;
    --padding-bottom: 32px;
    --padding-start: 32px;
    --padding-end: 32px;
  }
}
ion-content {
  --background: var(--nrm-color-bg);
}

.ql-editor {
  min-height: 200px;
}

.card {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(black, .12);
  padding: 16px;

  &.scrollable {
    overflow: scroll;
  }
}

.card-title {
  align-items: center;
  border-bottom: 1px solid var(--nrm-color-border);
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
  letter-spacing: .05em;
  padding: 12px 16px;
}

.card-body {
  padding: 12px;
}

.empty-state {
  align-items: center;
  color: var(--nrm-color-text-light);
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  padding: 40px;
}

ion-modal.partial-modal {
  --background: transparent !important;
  --box-shadow: none !important;
  ion-content,
  .ion-page,
  .modal-wrapper {
    --background: transparent !important;
    background-color: transparent !important;
    pointer-events: none !important;
  }
  .partial-modal {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    pointer-events: none;
  }
  .partial-modal-inner {
    pointer-events: auto;
  }
}

/*********** alert ************/
.alert-button {
  &.danger {
    color: var(--ion-color-danger);
  }
}
.wide-alert {
  --max-width: none;
}
.new-pc-selector {
  .alert-wrapper {
    --max-width: none;
    --width: 500px;
  }
  .alert-radio-group {
    --max-height: 320px;
  }
  .alert-radio-label {
    text-overflow: initial;
    white-space: initial;
  }
}
