.node-wrapper {
  line-height: 2;
  position: relative;
  &::before {
    border-bottom: 1px solid var(--davada-color-border);
    border-left: 1px solid var(--davada-color-border);
    content: '';
    display: block;
    height: 1em;
    left: -15px;
    position: absolute;
    top: 0;
    width: 10px;
  }
  &::after {
    border-left: 1px solid var(--davada-color-border);
    bottom: -7px;
    content: '';
    display: block;
    height: 100%;
    left: -15px;
    position: absolute;
  }
}
.tree-children {
  overflow: visible;
  position: relative;
  &::after {
    border-left: 1px solid var(--davada-color-border);
    bottom: -7px;
    content: '';
    display: block;
    height: 100%;
    left: -15px;
    position: absolute;
  }
}
tree-node:last-child {
  & > * > * > .node-wrapper::after {
    display: none;
  }
  & > * > * > .tree-children::after {
    display: none;
  }
}
.tree-node-level-1 > * > .node-wrapper {
  margin-left: 0;
  &::before,
  &::after {
    display: none;
  }
}
.tree-node-leaf > * > .node-wrapper::before {
  width: 26px;
}
.node-content-wrapper {
  flex: 1;
  padding: 0 4px;
}
tree-node-expander {
  align-items: center;
  align-self: stretch;
  display: flex;
  order: -1;
  width: 15px;
  .toggle-children-wrapper {
    padding: 0;
  }
  .toggle-children {
    display: block;
    position: static;
  }
}
